/*
 * @Author: 顾喜斌 xibin_gu@163.com
 * @Date: 2023-02-09 18:54:03
 * @LastEditors: 顾喜斌 xibin_gu@163.com
 * @LastEditTime: 2023-02-09 22:42:48
 */
import { getLocalStorage } from '@/utils/storage'
import { getQueryStringApp } from '@/utils/utils'
/**
 * location.href 跳转
 * @returns 
 */
export const jumpHref = (url) => {
    let _url = url
    // 不包含租户ID 进行拼接
    const tenantId = getLocalStorage('tenantId') || getQueryStringApp('tenantId') || ''
    if (!_url.includes('tenantId') && tenantId) {
        const str = _url.includes('?') ?'&':'?'
        _url = _url + str + 'tenantId=' + tenantId
    }
    // 不包含trdType 进行拼接
    const trdType = getLocalStorage('trdType') || getQueryStringApp('trdType') || ''
    if (!_url.includes('trdType') && trdType) {
        const str = _url.includes('?') ?'&':'?'
        _url = _url + str + 'trdType=' + trdType
    }
    window.location.href = _url
}

/**
 * location.replace 跳转
 * @returns 
 */
export const jumpReplace = (url) => {
    let _url = url
    // 不包含租户ID 进行拼接
    const tenantId = getLocalStorage('tenantId') || getQueryStringApp('tenantId') || ''
    if (!_url.includes('tenantId') && tenantId) {
        const str = _url.includes('?') ?'&':'?'
        _url = _url + str + 'tenantId=' + tenantId
    }
    // 不包含trdType 进行拼接
    const trdType = getLocalStorage('trdType') || getQueryStringApp('trdType') || ''
    if (!_url.includes('trdType') && trdType) {
        const str = _url.includes('?') ?'&':'?'
        _url = _url + str + 'trdType=' + trdType
    }
    window.location.replace(_url)
}
