import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'amfe-flexible';
import Vant from 'vant';
import 'vant/lib/index.css';
import axios from 'axios'
import './utils/rem'
import dragVerify from 'vue-drag-verify2'
Vue.use(dragVerify)
Vue.use(Vant);
Vue.config.productionTip = false

//弹出框禁止滑动
Vue.prototype.noScroll = function () {
    var mo = function (e) { e.preventDefault() }
    document.body.style.overflow = 'hidden'
    document.addEventListener('touchmove', mo, false)// 禁止页面滑动
}
 
//弹出框可以滑动
Vue.prototype.canScroll = function () {
    var mo = function (e) {
        e.preventDefault()
    }
    document.body.style.overflow = ''// 出现滚动条
    document.removeEventListener('touchmove', mo, false)
}

new Vue({
    axios,
    router,
    store,
    render: h => h(App)
}).$mount('#app')
