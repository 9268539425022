/*
 * @Author: 顾喜斌 xibin_gu@163.com
 * @Date: 2023-02-09 15:48:01
 * @LastEditors: 顾喜斌 xibin_gu@163.com
 * @LastEditTime: 2023-02-09 23:13:02
 */
import { getQueryStringApp } from './utils'

/**
 * localStorage 的存储
 */
export function setLocalStorage (key, value) {
    if (!getQueryStringApp('tenantId')) return false
    window.localStorage.setItem(key + getQueryStringApp('tenantId'), JSON.stringify(value))
}
/**
 * localStorage 的读取
 */
export function getLocalStorage (key) {
    if (!getQueryStringApp('tenantId')) return false
    const value = window.localStorage.getItem(key + getQueryStringApp('tenantId'))
    try {
        return JSON.parse(window.localStorage.getItem(key + getQueryStringApp('tenantId')))
    } catch (error) {
        return value
    }
}
/**
 * localStorage 的删除
 */
export function removeLocalStorage (key) {
    if (!getQueryStringApp('tenantId')) return false
    window.localStorage.removeItem(key + getQueryStringApp('tenantId'))
}