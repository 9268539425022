/*
 * @Author: 顾喜斌 xibin_gu@163.com
 * @Date: 2023-01-17 14:46:18
 * @LastEditors: 顾喜斌 xibin_gu@163.com
 * @LastEditTime: 2023-02-01 23:12:09
 */
import {request} from '../utils/request'

/**
 * 获取重定向地址
 * @param {*} params 
 * @returns 
 */
export function getCodeAndRedirect(params={}) {
    return request({
        url: '/h5/frontUser/getCodeAndRedirect',
        method: 'get',
        params
    })
}
/**
 * 获取签名权限
 * @param {*} params 
 * @returns 
 */
export function createSignature(data={}) {
    return request({
        url: '/h5/frontUser/getTrdConfig',
        method: 'post',
        data
    })
}
