/*
 * @Author: 顾喜斌 xibin_gu@163.com
 * @Date: 2023-01-17 14:15:00
 * @LastEditors: 顾喜斌 xibin_gu@163.com
 * @LastEditTime: 2023-02-24 16:21:52
 */
import { USER_INFO, USER_BALANCE } from '@/api/user'
import { setLocalStorage, getLocalStorage } from '@/utils/storage'
const state = {
    userInfo: getLocalStorage('userInfo') || null,
    userBalance: getLocalStorage('userBalance') || 0
}
const getters = {

}
const actions = {
    /**
     * @description 获取用户信息
     */
    // eslint-disable-next-line no-unused-vars
    async getUserInfo ({ commit, dispatch }) {
        const res = await USER_INFO()
        if (res) {
            setLocalStorage('userInfo', res)
            commit('setUserInfo', res)
        }
    },
     /**
     * @description 获取用户余额
     */
    // eslint-disable-next-line no-unused-vars
    async getUserBalance ({ commit, dispatch }) {
        const res = await USER_BALANCE()
        if (res || res === 0 || res === '0') {
            setLocalStorage('userBalance', res)
            commit('setUserBalance', res)
        }
    }
}
const mutations = {
    // 更新用户信息
    setUserInfo (state, userInfo) {
        state.userInfo = userInfo
    },
    // 获取用户余额
    setUserBalance (state, userBalance) {
        state.userBalance = userBalance
    }
}

const store_modular = {
    namespaced: true,//开启命名空间，命名空间是为了解决方法可能出现命名重复的问题
    state,
    getters,
    actions,
    mutations
}
export default store_modular
