import axios from 'axios'
import { get, isEmpty, merge } from 'lodash'
import qs from 'qs'
import { Dialog, Toast } from 'vant'
import { setLocalStorage, getLocalStorage, removeLocalStorage } from '@/utils/storage'
import { jumpReplace } from '@/utils/jump'
import { getQueryStringApp } from '@/utils/utils'
import wx from 'weixin-js-sdk'
const baseURL = '/api'
/**
 * @description 创建请求实例
 */
function createService () {
    // 创建一个 axios 实例
    const service = axios.create()
    // 请求拦截
    service.interceptors.request.use(
        config => {
            const token = getLocalStorage('token') || getQueryStringApp('token') || ''
            if (token) {
                config.headers.UserAuthorization = token
            }
            return config
        },
        error => {
            // 发送失败
            return Promise.reject(error)
        }
    )
    // 响应拦截
    // 标志当前是否正在刷洗token
    let isNotRefreshing = true
    // 请求队列
    let requests = []
    service.interceptors.response.use(
        async response => {
            // 没有 code 视为非项目接口不作处理
            if (response.data.code === undefined) {
                return response.data
            }
            // 有 code 判断为项目接口请求
            switch (Number(response.data.code)) {
                // 返回响应内容
                case 0: return response.data.data
                // 例如在 code 40113 情况下退回到登录页面
                case 40113: {
                    Toast.fail(response.data.msg)
                    const hrefUrl = window.location.href
                    if (hrefUrl.includes('trayNo=')) {
                        const trdType = getLocalStorage('trdType') ||  getQueryStringApp('trdType') || ''
                        const tenantId = getLocalStorage('tenantId') ||  getQueryStringApp('tenantId') || ''
                        setLocalStorage('currentAppUrl', location.origin +'/tray?trdType=' + trdType + '&tenantId=' + tenantId + '&loginFlag=true')
                    }else if (
                        !hrefUrl.includes('/register') && 
                        !hrefUrl.includes('/login') && 
                        !hrefUrl.includes('/notFollowed') && 
                        !hrefUrl.includes('/notTokenPage')
                    ) {
                        setLocalStorage('currentAppUrl', hrefUrl)
                    }
                    removeLocalStorage('token')
                    removeLocalStorage('openId')
                    removeLocalStorage('userInfo')
                    jumpReplace(location.origin +'/login')
                    break
                }
                // 40115 更新token
                case 40115: {
                    const config = response.config
                    // 如果当前不处于刷新阶段就进行刷新操作
                    if (isNotRefreshing) {
                        isNotRefreshing = false
                        // 刷新token refreshToken为true
                        return service.post(baseURL+'/h5/frontUser/changeToken', {
                            token: getLocalStorage('token') || getQueryStringApp('token') || '',
                            tenantId: getLocalStorage('tenantId') || getQueryStringApp('tenantId') || ''
                        }).then(res => {
                            setLocalStorage('token', res)
                            // 执行requests队列中的请求，（requests中存的不是请求参数，而是请求的Promise函数，这里直接拿来执行就好）
                            requests.forEach(run => run())
                            // 将请求队列置空
                            requests = []
                            // 重新执行当前未执行成功的请求并返回
                            return service(config)
                        }).finally(() => {
                            isNotRefreshing = true
                        })
                    } else {
                        // 如果当前已经是处于刷新token的状态，就将请求置于请求队列中，这个队列会在刷新token的回调中执行，由于new关键子存在声明提升，所以不用顾虑会有请求没有处理完的情况，这段添加请求的程序一定会在刷新token的回调执行之前执行的
                        return new Promise(resolve => {
                            // 这里加入的是一个promise的解析函数，将响应的config配置对应解析的请求函数存到requests中，等到刷新token回调后再执行
                            requests.push(() => {
                                resolve(service(config))
                            })
                        })
                    }
                }
                // 40117 订单不属于当前用户
                case 40117: {
                    Dialog.alert({
                        title: response.data.msg,
                        message: '',
                        confirmButtonText: '关闭',
                        confirmButtonColor: '#007AFF'
                    }).then(() => {
                        wx.closeWindow()
                    })
                }
                // 判断托盘是否可以进行绑定
                case 400008: return response.data.msg
                // 根据需要添加其它判断
                default: {
                    Toast.fail(response.data.msg)
                    throw new Error(`${response.data.msg}: ${response.config.url}`)
                }
            }
        },
        error => {
            const status = get(error, 'response.status')
            switch (status) {
                case 400: error.message = '请求错误'; break
                case 401: error.message = '未授权，请登录'; break
                case 403: error.message = '拒绝访问'; break
                case 404: error.message = `请求地址出错: ${error.response.config.url}`; break
                case 408: error.message = '请求超时'; break
                case 500: error.message = '服务器内部错误'; break
                case 501: error.message = '服务未实现'; break
                case 502: error.message = '网关错误'; break
                case 503: error.message = '服务不可用'; break
                case 504: error.message = '网关超时'; break
                case 505: error.message = 'HTTP版本不受支持'; break
                default: break
            }
            Toast.fail('系统错误，请稍后再试')
            return 'error'
            // throw error
        }
    )
    return service
}

function stringify (data) {
    return qs.stringify(data, { allowDots: true, encode: false })
}

/**
 * @description 创建请求方法
 * @param {Object} service axios 实例
 */
function createRequest (service) {
    return function (config) {
        const configDefault = {
            headers: {
                'Content-Type': get(config, 'headers.Content-Type', 'application/json')
            },
            timeout: 10000,
            baseURL,
            data: {}
        }
        // 共同参数 租户ID type
        if (config.method === 'post') {
            config.data.tenantId = getLocalStorage('tenantId') || getQueryStringApp('tenantId') || ''
            config.data.trdType = getLocalStorage('trdType') || getQueryStringApp('trdType') || ''
            config.data.trdUserId = getLocalStorage('openId') || getQueryStringApp('openId') || ''
        } else if (config.method === 'get') {
            config.params.tenantId = getLocalStorage('tenantId') || getQueryStringApp('tenantId') || ''
            config.params.trdType = getLocalStorage('trdType') || getQueryStringApp('trdType') || ''
        }
        const option = merge(configDefault, config)
        // 处理 get 请求的参数
        // 请根据实际需要修改
        if (!isEmpty(option.params)) {
            option.url = option.url + '?' + stringify(option.params)
            option.params = {}
        }
        // 当需要以 form 形式发送时 处理发送的数据
        // 请根据实际需要修改
        if (!isEmpty(option.data) && option.headers['Content-Type'] === 'application/x-www-form-urlencoded') {
            option.data = stringify(option.data)
        }
        return service(option)
    }
}

// 用于真实网络请求的实例和请求方法
export const service = createService()
export const request = createRequest(service)
